.App {
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr;
  min-height: 100%;
}

@media only screen and (max-width: 1600px) {
  .App {
    grid-template-columns: 1.8fr 1fr;
  }
}

@media only screen and (max-width: 960px) {
  .App {
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 680px) {
  .App {
    grid-template-rows: 800px 700px 600px;
  }
}
