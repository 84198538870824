.panel.photos {
  background: #112137;
  grid-template-rows: 2fr 1fr;
  overflow: hidden;
  color: #e0e1e4;
}

.panel.photos img {
  max-width: 100%;
  height: auto;
  color: #e0e1e4;
  display: block;
}

.panel.photos h2 {
  text-align: center;
  padding-top: 100px;
}

@media only screen and (max-width: 1600px) {
  .panel.photos {
    grid-template-rows: 2fr 1fr;
    grid-row: span 1;
  }

  .panel.photos img {
    display: block;
    max-height: 700px;
    min-width: 100%;
    justify-self: center;
  }

  .panel.photos h2 {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .panel.photos {
    grid-column: span 2;
    grid-template-rows: 3fr 1fr;
  }

  .panel.photos h2 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 680px) {
  .panel.photos {
    grid-template-rows: 1fr 1fr;
    height: 700px;
  }

  .panel.photos img {
    height: 550px;
  }

  .panel.photos h2 {
    padding-top: 25px;
  }
}
