@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;800&family=VT323&display=swap");

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins";
}

#root {
  height: 100%;
}

.panel {
  display: grid;
  grid-template-columns: 1fr;
}
