.panel.about {
  background: #cdddee;
  padding: 30px 60px;
  grid-auto-rows: 1fr;
  grid-template-rows: 1fr 2fr;
}

.panel.about h1 {
  color: #43536f;
  font-size: 3em;
  text-align: center;
  letter-spacing: 0.2rem;
  border-bottom: solid rgba(177, 168, 168, 0.253);
  padding-top: 150px;
  margin: 0;
}

.panel.about p {
  color: #43536f;
  line-height: 2em;
  font-size: 1.5em;
  text-align: center;
  padding-top: 60px;
}

@media only screen and (max-width: 1600px) {
  .panel.about {
    grid-template-rows: 1fr 2fr;
  }
}

@media only screen and (max-width: 960px) {
  .panel.about {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 680px) {
  .panel.about {
    padding: 30px 40px;
    grid-template-rows: 0.5fr 1fr;
  }

  .panel.about p {
    padding-top: 10px;
  }
}
